import { createApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'


import App from './App.vue'
import ApiService from "@/common/plugins/api.service";
import UtilPlugins from "@/common/plugins/util.service";
import router from './router'

import "@/assets/css/main.css";
import "@/assets/css/custom.css";

import EasyUI from 'v3-easyui';

// import Echo from "laravel-echo"

window.Pusher = require('pusher-js');
// window.Pusher.logToConsole = true;
// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: 'YKHMS',
//     wsHost: '127.0.0.1',
//     wsPort: 6001,
//     cluster: "mt1",
//     forceTLS: false,
//     disableStats: true,
// });

const app = createApp(App)

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

app.use(router)
app.use(pinia)
app.use(EasyUI)
app.use(ApiService)
app.use(UtilPlugins)
app.mount('#app')

router.beforeEach(async (to, from, next) => {
  console.warn({from:from.path,to:to.path});
  if (from.path ==="/" && to.path.match(/\/auth/)){
    console.warn("router : no login");
    next();
  }

  if (from.path ==="/" && to.path.match(/\/antrian/)){
    console.warn("router : no login");
    next();
  }

  /**
   * --------------------------------------------------
   * open page need auth
   * --------------------------------------------------
   */
  else{
    const {$api, $end} = app.config.globalProperties;
    await $api.get($end.auth.verify).then(()=>{
      if (to.path === "/auth"){
        next({ name: "main_page"})
      }else{
        next();
      }
    }).catch((error)=>{
      if (error.code === 401){
        next({ name: 'login' })
      }else{
        next();
      }
    })
  }

})
import axios from "axios";
import objPath from "object-path";
import endpoint from "@/common/config/endpoint.config.json"
import {useCookies} from "@vueuse/integrations/useCookies"

export default {
  install: (app) =>{

    axios.defaults.baseURL = process.env.VUE_APP_API_URL;
    axios.defaults.withCredentials = true;
    axios.defaults.headers["Accept"] = "application/json";
    axios.defaults.headers["X-XSRF-TOKEN"] = useCookies().get("XSRF-TOKEN");

    axios.interceptors.response.use(function (response) {
      return {
        code: response.status,
        message: objPath.get(response,"data.status"),//response.data.status,
        data: objPath.get(response,"data.data")//response.data.data
      };
    }, function (error) {
      const httpcode= objPath.get(error,"response.status")
      return Promise.reject({
        code: httpcode,
        message: objPath.get(error,"response.data.message"),
      });
    });


    app.config.globalProperties.$api = {

      get: (endpoint, options={}) => {
        if (endpoint === undefined){
          return Promise.reject({
            code: 400,
            message: "endpoint is undefined!",
          });
        }
        return axios.get(endpoint, options)
      },

      post: (endpoint, data, options = {}) =>{
        if (endpoint === undefined){
          return Promise.reject({
            code: 400,
            message: "endpoint is undefined!",
          });
        }
        return axios.post(endpoint, data, options)
      },

      put: (endpoint, data, options = {}) =>{
        return axios.put(endpoint, data, options)
      },

      delete: (endpoint, options = {}) =>{
        return axios.delete(endpoint, options)
      }

    }

    /**
     * --------------------------------------------------
     * endpoint collections
     * --------------------------------------------------
     */
    app.config.globalProperties.$end = endpoint
  }
}
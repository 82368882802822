import {createRouter, createWebHistory} from "vue-router"

const router =  createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "main_base",
      component: () => import("@/views/Main_View.vue"),
      children:[
        {
          path: "/",
          name: "main_page",
          component: () => import("@/components/pages/MainPages.vue")
        }
      ]
    },
    {
      path: "/auth",
      name: "auth",
      component: () => import("@/views/Auth_View.vue"),
      children:[
        {
          path: "",
          name: "login",
          component: () => import("@/components/auth/Login.vue")
        },
        {
          path: "forgot",
          name: "forgot",
          component: () => import("@/components/auth/Forgot.vue")
        }
      ]
    },
    {
      path:"/ex",
      name: "example",
      component: () => import("@/views/Example.vue"),
      children:[
        {
          path:":name",
          component: () => import("@/components/example/main.vue")
        }
      ]
    },
    {
      name: "print",
      path: "/print",
      component: () => import("@/views/Print_View.vue"),
      children: [
        {
          path: "antrian/:id",
          props: true,
          name: "print_antrian",
          component: () => import("@/components/print/Antrian.vue")
        },
        {
          path: "invoice/:id",
          props: true,
          name: "print_invoice",
          component: () => import("@/components/print/Invoice.vue")
        },
        {
          path: "skriningresep/:id",
          props: true,
          name: "print_skriningresep",
          component: () => import("@/components/print/SkriningResep.vue")
        },
        {
          path: "resep/:id",
          props: true,
          name: "print_resep",
          component: () => import("@/components/print/ResepObat.vue")
        },
        {
          path: "reseplabel/:id",
          props: true,
          name: "print_reseplabel",
          component: () => import("@/components/print/ResepLabel.vue")
        },
      ]
    },
    {
      path: "/antrian-poli",
      name: "antrian-pasien",
      component: () => import("@/views/Auth_View.vue"),
      children:[
        {
          path: ":departmentId",
          name: "main-antrian",
          props: true,
          component: () => import("@/views/Antrian_View.vue")
        }
      ]
    },
    { path: '/:pathMatch(.*)*', name: 'NotFound', component: ()=>import("@/views/Error_View.vue") },
  ]
})

export default router;